<template>
    <div class="v-archive-index" :class="{'filters-showed' : showFilters}">
        <div class="view-content">
            <div v-if="Object.keys(activeFilters).length || Object.keys(preselectedFilters).length" class="container-fluid view-content-header">
                <archive-active-filters v-if="Object.keys($globalData.archiveCategories[this.$i18n.locale]).length" :active-filters="activeFilters" @activeFilterRemoved="onActiveFilterRemoved" @preselectedFilterToggled="onPreselectedFilterToggle" :preselectedFilters="preselectedFilters" key="archive-overview-active-filters" :toggle-more-filters="true" />
            </div>
            <archive-overview ref="archiveOverviewComponent" v-if="itemsFetched && items.length" :items='items' :key="archiveOverviewComponentKey" :componentKey="archiveOverviewComponentKey" :is-showcase-archive="isShowcaseArchive" />

            <div v-else class="container">
                <p v-if="itemsFetched" class="no-entries">{{$t('archive.index.noEntries')}}</p>
                <p v-else class="loader-spinner"><span>Loading...</span></p>
            </div>
            <div class="archive-filters-btn-container">
                <a v-if="archiveCategoriesFetched" class="archive-filters-btn" @click="showFilters = true">
                    <span class="btn-label">Filter</span>
                </a>
            </div>

            <archive-filters v-if="showFilters" @close-filters="showFilters = false" :items-total="itemsTotal" :active-filters="activeFilters" :available-filters="availableFilters" />
        </div>
    </div>
</template>

<script>
import ArchiveActiveFilters from '@/components/ArchiveActiveFilters.vue';
import ArchiveOverview from '@/components/ArchiveOverview.vue';
import ArchiveFilters from '@/components/ArchiveFilters.vue';

import { fetchArchiveEntries } from '../http/fetchApi';
import { fetchArchiveCategories } from '../http/fetchApi';

export default {
    name: 'ArchiveIndex',
    components: {
        ArchiveOverview,
        ArchiveFilters,
        ArchiveActiveFilters,
    },
    props: {
        filterQuery: Object,
        preselectedFilters: {
            default: function () {
                return {}
            }
        },
        isShowcaseArchive: {
            default: false
        }
    },
    data() {
        return {
            items: {}, //[],
            itemsTotal: 0,
            itemsFetched: false,
            showFilters: false,
            availableFilters: {},
            activeFilters: {},
            archiveOverviewComponentKey:'archiveOverview',
            routeLeftToArchiveEntry: false,
            storedScrollPositionOnRouteLeave:0,
            reloadItemsBeforeNextUpdate: false,
            resizeT: false,
            archiveCategoriesFetched: false
        }
    },
    computed: {
        hasArchiveFilters() {
            return Object.keys(this.$globalData.archiveCategories[this.$i18n.locale]).length;
        }
    },
    created() {
        this.setArchiveOverviewComponentKey();
    },
    mounted() {
        // custom key needed when called as child component from showcase entry
        const urlParams = new URLSearchParams(this.filterQuery).toString();
        this.formatQueryFilters();
        this.fetchEntries(this.filterQuery);
        if (!Object.keys(this.$globalData.archiveCategories[this.$i18n.locale]).length) {
            this.fetchCategories();
        } else {
            this.archiveCategoriesFetched = true;
        }
        window.addEventListener('resize', this.onWindowResize);
    },
    beforeUpdate() {
        if (this.reloadItemsBeforeNextUpdate && Object.keys(this.items).length && typeof this.$refs.archiveOverviewComponent !== 'undefined') {
            const items = this.items;
            this.items = {};
            this.$refs.archiveOverviewComponent.resetMasonry();
            const newItems = this.shuffleItems(items);
            this.items = newItems;
        }
    },
    updated() {
        this.$nextTick(function () {
            if (this.routeLeftToArchiveEntry) {
                this.$el.scrollTop = this.storedScrollPositionOnRouteLeave;
                this.routeLeftToArchiveEntry = false;
                this.storedScrollPositionOnRouteLeave = 0;
            }
        });
    },
    unmounted() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.reloadItemsBeforeNextUpdate && Object.keys(vm.items).length) {
                window.dispatchEvent(new Event('resize'));
                this.reloadItemsBeforeNextUpdate = false;
                //Resize
                setTimeout(function() {
                    window.dispatchEvent(new Event('resize'));
                }, 900);
            }
        })
    },
    deactivated(){
        // workaround "inconsistent" on route leave behaviour: always store archive entries when component is deaktivated
        this.$globalData.storedArchiveEntries = this.items;
    },
    beforeRouteLeave (to, from) {
        if (to.name !== from.name) {
            this.showFilters = false;
        }
        if (to.name == 'ArchiveEntry') {
            this.routeLeftToArchiveEntry = true;
            this.storedScrollPositionOnRouteLeave = this.$el.scrollTop;
        } else {
            this.reloadItemsBeforeNextUpdate = true;
        }
    },
    methods: {
        async fetchEntries(params) {
            const { data } = await fetchArchiveEntries(params);
            this.items = this.shuffleItems(data.results);
            this.itemsTotal = data.total;
            this.availableFilters = data.subfilters;
            this.itemsFetched = true;

            // set header data
            if (this.$route.name != 'ShowcaseEntry') {
                this.$globalData.setHeaderData({
                    title: 'F+F 1971 - Archiv'
                });
            }
        },
        async fetchCategories() {
            const { data } = await fetchArchiveCategories();
            this.$globalData.archiveCategories[this.$i18n.locale] = data;
            this.archiveCategoriesFetched = true;
        },
        shuffleItems(items) {
            for(let i = items.length - 1; i > 0; i--){
                const j = Math.floor(Math.random() * i)
                const temp = items[i]
                items[i] = items[j]
                items[j] = temp
            }
            return items;
        },
        onCloseFilters() {
            this.showFilters = false;
        },
        formatQueryFilters() {
            for (var key in this.filterQuery) {
                const filterValues = this.filterQuery[key];
                this.activeFilters[key] = typeof filterValues == 'object' ? filterValues : [filterValues];
            }
        },
        onActiveFilterRemoved(groupKey, filterTerm) {
            this.activeFilters[groupKey] = this.activeFilters[groupKey].filter(filter => filter !== filterTerm);
            if (!this.activeFilters[groupKey].length) {
                delete this.activeFilters[groupKey];
            }
            if (this.$route.name == 'ShowcaseEntry') {
                    this.$route.meta.activeView = 'archive'
            }
            this.$router.push({ name: 'ArchiveIndex', query: this.activeFilters, params: { locale: this.$route.params.locale} });
        },
        onPreselectedFilterToggle(groupKey, filterId) {
            const sectionHeaderHeight = document.getElementsByClassName("section-header")[0].clientHeight;
            const headersTotalSpace = 3 * sectionHeaderHeight;
            this.$el.style.minHeight =( window.innerHeight - Math.max(this.$el.getBoundingClientRect().top, headersTotalSpace)) + 'px';

            this.activeFilters[groupKey] = this.activeFilters[groupKey] || [];
            if (this.activeFilters[groupKey].includes(filterId)) {
                this.activeFilters[groupKey] = this.activeFilters[groupKey].filter(filter => filter !== filterId);
                if (!this.activeFilters[groupKey].length) {
                    delete this.activeFilters[groupKey]
                }
            } else {
                this.activeFilters[groupKey].push(filterId);
            }

            this.items = {};
            this.itemsFetched = false;
            this.fetchEntries(this.activeFilters);
        },
        setArchiveOverviewComponentKey() {
            const keyParamsStr = Object.values(this.filterQuery).map(function(value){
                return Array.isArray(value) ? value.join('-') : value;
            }).join('-');
            this.archiveOverviewComponentKey = 'archiveOverview-'+ (this.$i18n.locale)+keyParamsStr;
        },
        onWindowResize() {
            if (this.resizeT){
                clearTimeout(this.resizeT);
            }
            const that = this;
            this.resizeT = setTimeout(function() {
                that.$el.style.minHeight = 0;
            }, 200);
        },
    },
}
</script>
