<template>
    <div class="c-archive-preview-item">
        <router-link :to="{ name: 'ArchiveEntry', params: { locale: $route.params.locale, slug: item.slug, index:index } }">
            <svg v-if="item.mediaType && item.mediaType.id == '137'" class="img-icon icon-pdf" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 39.76 46.92" xml:space="preserve"><path d="M30.01 9.66V0H0v37.26h9.74v9.66h30.01V9.66h-9.74zM3.8 33.46V3.8h22.41v5.85H9.74v23.8H3.8zm32.16 9.65H13.54V13.46h22.41v29.65z"/></svg>
            <svg v-if="item.mediaType && item.mediaType.id == '135'" class="img-icon icon-audio" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 54.91 44.4" xml:space="preserve"><path d="M0 14.8h4v14.8H0zM9.38 7.4h4V37h-4zM19.76 0h4v44.4h-4zM30.14 14.8h4v14.8h-4zM40.53 7.4h4V37h-4zM50.91 0h4v44.4h-4z"/></svg>
            <svg v-if="item.mediaType && item.mediaType.id == '138'" class="img-icon icon-video" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 36.76 46.63" xml:space="preserve"><path d="M32.76 0v4.12h-4.24V0H8.24v4.12H4V0H0v46.63h4v-4.12h4.24v4.12h20.27v-4.12h4.24v4.12h4V0h-3.99zm0 8.12v7.47h-4.24V8.12h4.24zm-4.24 11.46h4.24v7.47h-4.24v-7.47zM8.24 27.05H4v-7.47h4.24v7.47zm0-18.93v7.47H4V8.12h4.24zM4 38.51v-7.47h4.24v7.47H4zm24.52 0v-7.47h4.24v7.47h-4.24z"/></svg>
            <img v-if="item.thumbnail_url" class="preview-image" :src="item.thumbnail_url" @load="$emit('imageLoaded', $el)" @error="$emit('imageLoaded', $el)" />
            <span v-else class="placeholder-image"></span>
            <h2 class="item-title">{{item.title}}</h2>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'ArchivePreviewItem',
    props: {
        item: {},
        index: null
    },
    emits: [
        'imageLoaded'
    ],
    mounted() {
        this.$nextTick(function () {
            if (!this.item.thumbnail_url) {
                const that = this;
                setTimeout(function() {
                    that.$emit('imageLoaded', that.$el)
                }, 200);
            }
        })
    }
}
</script>
