<template>
    <div class="c-archive-overview container-fluid px-0">
        <!--<div class="masonry-wrapper">-->
            <div :id="componentKey" class="masonry">
                <div class="masonry-grid-sizer"></div>
                <div class="masonry-gutter-sizer"></div>
                <template v-for="(item, index) in items">
                    <archive-preview-item  v-if="displayedItemsMax > index" class="masonry-item" :key="componentKey+'-'+(item.reference_code?item.reference_code.replace(/\s/g, '_' ) : Date.now())+'-'+$i18n.locale+'-'+index" :item="item" :index=index @image-loaded="onImagesLoaded($event, index)"/>
                </template>
            </div>
        <!--</div>-->
    </div>
</template>

<script>

import ArchivePreviewItem from '@/components/ArchivePreviewItem.vue';

const Masonry = require('masonry-layout');

export default {
    name: 'ArchiveOverview',
    components: {
        ArchivePreviewItem
    },
    props: {
        items: Array,
        componentKey: String,
        isShowcaseArchive: {
            default: false
        }
    },
    data() {
        return {
            masonryObj:false,
            imagesLoadedT: false,
            //masonryElementId: this.$i18n.locale+this.$route.name,
            scrollContainer: null,
            masonryCols: 4,
            minPreloadedMasonryRows: 4,
            isInViewportOffset: 0,
            itemsReadyToShowInViewport: {},
            masonryLayoutT: false,
            displayedItemsMax: 4,
            scrollTicking: false,
            resizeT: false,
        }
    },
    mounted: function() {
        this.$nextTick(function () {
            this.scrollContainer = this.$el.closest(this.isShowcaseArchive ? '.v-showcase-entry-scroll-container' : '.v-archive-index');
            this.initMasonry();
            window.addEventListener('resize', this.onWindowResize);
        })
    },
    updated() {
        this.$nextTick(function () {
            this.masonryObj.reloadItems();
        })
    },
    unmounted() {
        if (this.scrollContainer != null) { // prevent error on hot reload
            this.scrollContainer.removeEventListener('scroll', this.onViewContentScrolled);
        }
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        initMasonry() {
            var masonryInstance = Masonry.data(this.$el);
            if (typeof masonryInstance == 'undefined' || masonryInstance == null) {
                const that = this;
                masonryInstance = new Masonry( this.$el, {
                    itemSelector: '.masonry-item',
                    columnWidth: '.masonry-grid-sizer',
                    gutter: '.masonry-gutter-sizer',
                    transitionDuration: 0,
                }).once( 'layoutComplete', function(items) {
                    that.setIsInViewportOffset();
                    that.scrollContainer.addEventListener('scroll', that.onViewContentScrolled);
                }).on( 'layoutComplete', function( items ) {
                    that.$nextTick(function () {
                        that.checkItemsReadyToShowInViewport();
                    });
                });
            } else {
                masonryInstance.reloadItems();
            }
            this.masonryObj = masonryInstance;
        },
        onImagesLoaded(itemElement, itemIndex) {
            itemElement.classList.remove('is-in-viewport');
            this.itemsReadyToShowInViewport[itemIndex] = itemElement;
            this.$nextTick(function () {
                this.layoutMasonry();
            });
        },
        showItemInViewport(itemKey, itemElement) {
            const minPreloadedMasonryItems = parseInt(itemKey)+(this.minPreloadedMasonryRows*this.masonryCols);
            this.displayedItemsMax = Math.max(minPreloadedMasonryItems, this.displayedItemsMax);
            itemElement.classList.add('is-in-viewport');
            delete this.itemsReadyToShowInViewport[itemKey];
        },
        layoutMasonry () {
            this.masonryObj.layout();
        },
        setIsInViewportOffset() {
            this.isInViewportOffset = window.innerHeight;
        },
        itemIsInViewport(itemElement) {
            const { top } = itemElement.getBoundingClientRect();
            return top <= this.isInViewportOffset;
        },
        checkItemsReadyToShowInViewport() {
            Object.keys(this.itemsReadyToShowInViewport).forEach((key, index) => {
                const itemElement = this.itemsReadyToShowInViewport[key];
                if (this.itemIsInViewport(itemElement)) {
                    this.showItemInViewport(key, itemElement);
                }
            });
        },
        resetMasonry() {
            const itemsInViewport = this.$el.querySelectorAll('.is-in-viewport');
            this.itemsReadyToShowInViewport = {};
            this.displayedItemsMax = 4;
            this.masonryObj.reloadItems();
        },
        onViewContentScrolled(e) {
            if (this.lastKnownScrollPosition < e.target.scrollTop && !this.scrollTicking) {
                this.scrollTicking = true;
                this.checkItemsReadyToShowInViewport();
                this.scrollTicking = false;
            }
            this.lastKnownScrollPosition = e.target.scrollTop;
        },
        onWindowResize() {
            if (this.resizeT){
                clearTimeout(this.resizeT);
            }
            const that = this;
            this.resizeT = setTimeout(function() {
                that.setIsInViewportOffset();
                that.checkItemsReadyToShowInViewport();
            }, 200);
        },
    },
}
</script>
