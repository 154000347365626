import axios from 'axios';
import { i18n } from '../i18n'

const BASE_URL = process.env.VUE_APP_API_BASE_URL+'/';
const API_PATH = 'api/'
const localePath = () => i18n.global.locale != 'de' ? i18n.global.locale + '/' : '';

export default async (url, method, options = {}) => axios ({
    method: method.toUpperCase(),
    url: BASE_URL + localePath() + API_PATH + url,
    ...options,
});
