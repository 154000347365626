<template>
    <nav class="c-main-navigation section-nav container-fluid">
        <router-link :to="{ name: 'OralHistoryIndex', params: { locale: $route.params.locale }}" :class="{'router-link-active': $route.name == 'OralHistoryEntry'}">Oral History,</router-link> <router-link :to="{ name: 'ActivitiesIndex', params: { locale: $route.params.locale }}" :class="{'router-link-active': $route.name == 'ActivitiesEntry'}">Activities,</router-link> <router-link :to="{ name: 'DefaultEntry', params: { locale: $route.params.locale, slug: 'about'} }">About</router-link>
    </nav>
</template>

<script>
export default {
    name: 'MainNavigation',
}
</script>
