import { createRouter, createWebHistory } from 'vue-router';

import { i18n, defaultLocale } from '../i18n'

import Home from '../views/Home.vue';
import ShowcaseCategoryIndex from '../views/ShowcaseCategoryIndex.vue';
import ArchiveIndex from '../views/ArchiveIndex.vue';

const routes = [
    {
        path: '/:locale(en|de)?/',
        name: 'Home',
        components: {
            default: Home,
        },
        meta: {
            activeView: 'default'
        }
    },
    {
        path: '/:locale(en|de)?/oral-history',
        name: 'OralHistoryIndex',
        components: {
            default: () => import('../views/OralHistoryIndex.vue'),
        },
        meta: {
            activeView: 'default'
        }
    },
    {
        path: '/:locale(en|de)?/oral-history/:slug',
        name: 'OralHistoryEntry',
        components: {
            default: () => import('../views/OralHistoryEntry.vue'),
        },
        props: {
            default: true,
        },
        meta: {
            activeView: 'default'
        }
    },
    {
        path: '/:locale(en|de)?/activities',
        name: 'ActivitiesIndex',
        components: {
            default: () => import('../views/ActivitiesIndex.vue'),
        },
        meta: {
            activeView: 'default'
        }
    },
    {
        path: '/:locale(en|de)?/activities/:slug',
        name: 'ActivitiesEntry',
        components: {
            default: () => import('../views/ActivitiesEntry.vue'),
        },
        props: {
            default: true,
        },
        meta: {
            activeView: 'default'
        }
    },
    {
        path: '/:locale(en|de)?/showcase',
        name: 'ShowcaseCategoryIndex',
        components: {
            showcase: ShowcaseCategoryIndex,
        },
        meta: {
            activeView: 'showcase'
        }
    },
    {
        path: '/:locale(en|de)?/showcase/:slug',
        name: 'ShowcaseIndex',
        components: {
            showcase: () => import('../views/ShowcaseIndex.vue'),
        },
        props: {
            showcase: true,
        },
        meta: {
            activeView: 'showcase'
        }
    },
    {
        path: '/:locale(en|de)?/showcase/:categorySlug/:slug',
        name: 'ShowcaseEntry',
        components: {
            showcase: () => import('../views/ShowcaseEntry.vue'),
        },
        props: {
            showcase: true,
        },
        meta: {
            activeView: 'showcase'
        }
    },
    {
        path: '/:locale(en|de)?/archive',
        alias: '/:locale(en|de)?/archiv',
        name: 'ArchiveIndex',
        components: {
            archive: ArchiveIndex,
        },
        props: {
            archive: route => ({ filterQuery: route.query })
        },
        meta: {
            activeView: 'archive',
        }
    },
    {
        path: '/:locale(en|de)?/archive/:slug',
        alias: '/:locale(en|de)?/archiv/:slug',
        name: 'ArchiveEntry',
        components: {
            overlay: () => import('../views/ArchiveEntry.vue'),
        },
        props: {
            overlay: true
        },
        meta: {
            activeView: 'overlay'
        }
    },
    {
        path: '/:locale(en|de)?/:slug',
        name: 'DefaultEntry',
        components: {
            default: () => import('../views/DefaultEntry.vue'),
        },
        props: {
            default: true,
        },
        meta: {
            activeView: 'default'
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})
router.beforeEach((to, from, next) => {
    if (to.params.locale === from.params.locale) {
        next()
        return
    }

    const lang = to.params.locale || defaultLocale
    i18n.global.locale = lang

    next();
});
router.afterEach((to, from) => {
    if (typeof from.name === 'undefined'
        && to.name == 'Home') {
        to.meta.dropDownTransitionDuration = 0;
        to.meta.dropDownTransitionName = 'init-home';
    } else if (typeof from.name === 'undefined'
        || from.meta.activeView == 'overlay'
        || to.meta.activeView == 'overlay'
        || (from.meta.activeView == to.meta.activeView && to.name != 'Home' && from.name != 'Home')
        || to.name == from.name) {
        to.meta.dropDownTransitionDuration = 0;
    } else if (from.meta.activeView == to.meta.activeView && (to.name == 'Home' || from.name == 'Home')) {
        to.meta.dropDownTransitionName = 'toggle-home';
    }
})

export default router
