import baseHttp from './baseHttp';

export const getHttp = async (
    url,
    params,
    options,
) => baseHttp(url,
    'get',
    {
        ...options,
        params
    }
);


// Oral History
export const fetchOralHistoryEntries = async () => baseHttp('oral-history.json',
    'get',
    {}
);
export const fetchOralHistoryEntryBySlug = async (slug) => baseHttp('oral-history/'+slug+'.json',
    'get',
    {}
);


// Activities
export const fetchActivitiesEntries = async () => baseHttp('activities.json',
    'get',
    {}
);

export const fetchActivitiesEntryBySlug = async (slug) => baseHttp('activities/'+slug+'.json',
    'get',
    {}
);

export const fetchShowcaseEntriesByCategorySlug = async (slug) => baseHttp('showcase-category/'+slug+'.json',
    'get',
    {}
);

export const fetchShowcaseEntryBySlug = async (slug) => baseHttp('showcase/'+slug+'.json',
    'get',
    {}
);


// Archive
export const fetchArchiveEntries = async (params) => {
    let allParams = {};
    let allParams_i = 0;
    let orderedParams_i = 0;
    for (var paramsGroupKey in params) {
        let groupParams = params[paramsGroupKey];
        if (typeof groupParams == 'string') {
            groupParams = [groupParams];
        }
        for (var param_i = 0; param_i < groupParams.length; param_i++) {
            if (paramsGroupKey == 'year') {
                allParams['startDate'] = groupParams[param_i]+'-01-01';
                allParams['endDate'] = groupParams[param_i]+'-12-31';
                allParams['rangeType'] = 'inclusive';
            } else if (paramsGroupKey == 'mediatypes') {
                allParams['mediatypes'] = groupParams[param_i];
            } else {
                if (allParams_i != 0) {
                    allParams['so'+orderedParams_i] = 'and';
                    //allParams['so'+allParams_i] = param_i == 0 ? 'and' : 'or';
                }
                if (paramsGroupKey != 'search') {
                    allParams['sf'+orderedParams_i] = paramsGroupKey;
                }
                allParams['sq'+orderedParams_i] = '"'+groupParams[param_i]+'"';
                orderedParams_i++;
            }
            allParams_i++;
        }
    }

    return baseHttp('archive.json',
        'get',
        {
            params:allParams
        }
    )
};

export const fetchArchiveCategories = async (params) => {
    return baseHttp('archive-filters.json',
        'get',
        {
            params:params
        }
    )
};

export const fetchArchiveEntryBySlug = async (slug) => baseHttp('archive/'+slug+'.json',
    'get',
    {}
);

// Default Entry
export const fetchDefaultEntryBySlug = async (slug) => baseHttp('pages/'+slug+'.json',
    'get',
    {}
);

// 	Activity Splash Button
export const fetchActivitySplashButton = async () => baseHttp('activity-splash-button.json',
    'get',
    {}
);

// 	Comment form
export const fetchCsrfToken = async () => baseHttp('csrf-token.json',
    'get',
    {}
);
export const submitCommentForm = async (data) => baseHttp('submit-comment',
    'post',
    {
        data,
    }
);
