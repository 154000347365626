<template>
    <nav v-if="$route.name" class="c-language-switcher">
        <template v-for="(locale) in $i18n.availableLocales" :key="'lang_'+locale">
            <span class="d-none d-sm-inline-block" v-if="locale == $i18n.locale">{{locale}}</span>
            <router-link v-else :to="getRouteTo(locale)">{{locale}}</router-link>
        </template>
    </nav>
</template>

<script>
//import { messages } from '../i18n'
export default {
    name: 'LanguageSwitcher',
    methods: {
        getRouteTo(locale) {
            const routerLinkObj = {
                name: this.$route.name,
            }
            // Quick Fix showcase category slugs
            const params = Object.assign({}, this.$route.params);
            params.locale = locale == this.$i18n.fallbackLocale ? '' : locale;
            if (this.$route.name == 'ShowcaseEntry' && typeof params.categorySlug !== 'undefined') {
                params.categorySlug = this.$i18n.messages[locale].showcase.categories.slugMapping[params.categorySlug];
            } else if (this.$route.name == 'ShowcaseIndex' && typeof params.slug !== 'undefined') {
                params.slug = this.$i18n.messages[locale].showcase.categories.slugMapping[params.slug];
           }
            routerLinkObj.params = params;
            return routerLinkObj;
        }
    }
}
</script>
