<template>
    <header id="site-header">
        <h1 class="site-title" :class="{'site-title-large': $route.name == 'Home'}"><router-link :to="{ name: 'OralHistoryIndex', params: { locale: $route.params.locale }}">F<span class="plus-text-for-search-engines">+</span><svg class="plus-icon" data-name="plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.12 125.12"><path fill="#1d1d1b" d="M125.12 61.25H65.14V0h-5.16v61.25H0v5.17h59.98v58.7h5.16v-58.7h59.98v-5.17z"/></svg>F 1971</router-link></h1>

        <language-switcher />
    </header>
    <main id="site-main">

        <section id="default" class="drop-down-section" :class="$route.meta.dropDownTransitionName || ''">
            <router-view v-slot="{ Component }">
                <transition :name="$route.meta.dropDownTransitionName || 'drop-down'"
                    @beforeEnter="dropDownTransitionBeforeEnter"
                    @enter="dropDownTransitionEnter"
                    @beforeLeave="dropdownTransitionBeforeLeave"
                    @leave="dropDownTransitionLeave"
                    :duration="$route.meta.dropDownTransitionDuration || defaultDropDownTransitionDuration"
                >
                    <keep-alive max="2">
                        <component :is="Component" v-show="$route.meta.activeView == 'default'" :key="getViewKey('default', '/')" class="section-content" />
                    </keep-alive>
                </transition>
            </router-view>
        </section>

        <section id="showcase" class="drop-down-section">
            <header class="section-header container-fluid px-0">
                <h1 class="section-title"><router-link :to="{ name: 'ShowcaseCategoryIndex', params: { locale: $route.params.locale } }">{{ $t('appSectionTitles.showcase')}}</router-link></h1>
            </header>
            <router-view class="view-showcase" name="showcase" v-slot="{ Component }">
                <transition name="drop-down"
                    @beforeEnter="dropDownTransitionBeforeEnter"
                    @enter="dropDownTransitionEnter"
                    @beforeLeave="dropdownTransitionBeforeLeave"
                    @leave="dropDownTransitionLeave"
                    :duration="$route.meta.dropDownTransitionDuration || defaultDropDownTransitionDuration"
                >
                    <keep-alive max="2">
                        <component :is="Component" v-show="$route.meta.activeView == 'showcase'" :key="getViewKey('showcase', '/showcase')" class="section-content" />
                    </keep-alive>
                </transition>
            </router-view>
        </section>

        <section id="archive" class="drop-down-section">
            <header class="section-header container-fluid px-0">
                <h1 class="section-title"><router-link :to="{ name: 'ArchiveIndex', params: { locale: $route.params.locale } }">{{ $t('appSectionTitles.archive')}}</router-link></h1>
            </header>
            <router-view class="view-archive" name="archive" v-slot="{ Component }">
                <transition name="drop-down"
                    @beforeEnter="dropDownTransitionBeforeEnter"
                    @enter="dropDownTransitionEnter"
                    @beforeLeave="dropdownTransitionBeforeLeave"
                    @leave="dropDownTransitionLeave"
                    :duration="$route.meta.dropDownTransitionDuration || defaultDropDownTransitionDuration"
                >
                    <keep-alive max="1">
                        <component :is="Component" v-show="$route.meta.activeView == 'archive'" :key="getViewKey('archive', '/archiv')" class="section-content" />
                    </keep-alive>
                </transition>
            </router-view>
        </section>

        <router-view class="view-overlay" name="overlay" :key="getViewKey('overlay', '/archiv-entry')"></router-view>

    </main>
</template>

<script>
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
export default {
    name: 'App',
    components: {
        LanguageSwitcher
    },
    data() {
        return {
            defaultDropDownTransitionDuration: 900,
            resizeT: false,
        }
    },
    computed: {
        dropDownTransitionDuration() {
            return typeof this.$route.meta.dropDownTransitionDuration !== 'undefined' ? this.$route.meta.dropDownTransitionDuration : this.defaultDropDownTransitionDuration
        }
    },
    created() {
        this.detectTouchDevice();
    },
    mounted() {
        window.addEventListener('resize', this.onWindowResize);
    },
    updated() {},
    unmounted() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        dropDownTransitionBeforeEnter(el) {
            el.style.height = '0px';
            el.style.transition = 'height '+ this.dropDownTransitionDuration +'ms ease';
        },
        dropDownTransitionEnter(el) {
            this.$nextTick(function () {
                el.style.height = this.getViewContainerHeight();
            })
        },
        dropdownTransitionBeforeLeave(el) {
            el.style.transition = 'height '+ this.dropDownTransitionDuration +'ms ease';
        },
        dropDownTransitionLeave(el) {
            this.$nextTick(function () {
                el.style.height = '0px';
            })
        },
        getViewContainerHeight() {
            const sectionHeaderHeight = document.getElementsByClassName("section-header")[0].clientHeight;
            //const headersTotalSpace = (this.$route.name == 'ShowcaseEntry' ? 2 : 3) * sectionHeaderHeight;
            const headersTotalSpace = 3 * sectionHeaderHeight;
            return window.innerHeight - headersTotalSpace +'px';
        },
        onWindowResize() {
            if (this.resizeT){
                clearTimeout(this.resizeT);
            }
            const that = this;
            this.resizeT = setTimeout(function() {
                if (that.$route.meta.activeView!='overlay' && document.getElementById(that.$route.meta.activeView)) {
                    document.getElementById(that.$route.meta.activeView).getElementsByClassName('section-content')[0].style.height = that.getViewContainerHeight();
                }
            }, 200);
        },
        getViewKey(slug, path) {
            if (this.$route.meta.activeView == slug) {
                return this.$route.fullPath
            } else {
                return (this.$i18n.locale == 'de' ? '' : '/'+this.$i18n.locale) + path;
            }
        },
        detectTouchDevice() {
            this.$globalData.isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
            document.documentElement.classList.add(this.$globalData.isTouchDevice ? 'is-touch' : 'no-touch');
        }
    }
}
</script>

<style lang="scss">
@import "./assets/scss/app.scss";
</style>
