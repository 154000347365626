<template>
    <div class="c-splash-button">
        <component :is="routerLinkData ? 'router-link' : 'a'" :href="routerLinkData ? '' : entry.linkedUrl" :to="routerLinkData" :target="routerLinkData ? null : '_blank'" class="button-link">
            <span class="button-label">
                <span v-if="entry.relatedEntry" class="date d-none d-md-block" v-html="entry.relatedEntry.date" />
                <span class="d-block d-md-none">{{ $t('showcase.splashbutton.from')}} </span>
                <span v-if="entry.relatedEntry" class="date d-block d-md-none" v-html="entryDateSmall"/>
                <span class="title d-none d-md-block" v-html="entry.title" />
            </span>
            <span class="button-rollover-label" v-html="entry.subtitle" />
        </component>
    </div>
</template>

<script>
export default {
    name: 'SplashButton',
    props: {
        entry: {},
    },
    data() {
        return {
            entryDateSmall: '',
            routerLinkData: null
        }
    },
    created () {
        this.setRouterLinkData();
        this.changeDateMobile();
    },
    methods: {
        setRouterLinkData() {
            var routerLinkData = null;
            if (this.entry.relatedEntry||false) {
                var relatedEntryRouteName = null;
                if (this.entry.relatedEntry.type||false) {
                    const type = this.entry.relatedEntry.type;
                    relatedEntryRouteName = (type.charAt(0).toUpperCase() + type.slice(1) + 'Entry');
                }
                routerLinkData = { name: relatedEntryRouteName, params: { locale: this.$route.params.locale, slug: this.entry.relatedEntry.slug }};
            } else if (this.entry.linkedUrl) {
                var baseUrl = process.env.VUE_APP_BASE_URL+'/'+(this.$i18n.locale != 'de' ? this.$i18n.locale + '/' : '');
                if (this.entry.linkedUrl.includes(baseUrl)) {
                    const routerPath = this.entry.linkedUrl.replace(baseUrl, '');
                    routerLinkData = { path: routerPath, params: { locale: this.$route.params.locale }};
                }
            }
            this.routerLinkData = routerLinkData;
        },
        changeDateMobile() {
            if (this.entry.relatedEntry || false) {
                var locale = this.$i18n.locale;
                var entryDate = this.entry.relatedEntry.startDate;
                if (locale =='de') {
                    var entryDateSmallDe = entryDate.slice(0, -4); // => "Tabs1"
                    this.entryDateSmall = entryDateSmallDe;
                }
                else if (locale =='en') {
                    var entryDateSmallEn = entryDate.slice(0, -5); // => "Tabs1"
                    this.entryDateSmall = entryDateSmallEn;
                }
            }
        },
    }
}
</script>
