<template>
    <div class="v-showcase-category-index">
        <div ref="viewContent" class="view-content">
            <splash-button v-if="activitySplashButtonEntry" :entry="activitySplashButtonEntry" />
            <showcase-category-overview :preview-items="previewItems" />
        </div>
    </div>
</template>

<script>
import ShowcaseCategoryOverview from '@/components/ShowcaseCategoryOverview.vue'
import SplashButton from '@/components/SplashButton.vue'

import { fetchActivitySplashButton } from '../http/fetchApi';

export default {
    name: 'ShowcaseCategoryIndex',
    components: {
        ShowcaseCategoryOverview,
        SplashButton
    },
    data() {
        return {
            activitySplashButtonEntry: null,
            bgColors: [ '#2f55ff', '#8600ff', '#ff554e', '#00c37e', '#ffdd2e'],
            bgColorsInUse:[],
            previewItems: [
                {
                    id: 'what',
                    contentType: 'image',
                    content: null,
                    bgColor: null
                },
                {
                    id: 'when',
                    contentType: 'logo',
                    content: null,
                    bgColor: null
                },
                {
                    id: 'how',
                    contentType: 'term',
                    content: null,
                    bgColor: null
                },
                {
                    id: 'who',
                    contentType: 'image',
                    content: null,
                    bgColor: null
                },
            ],
            availablePreviewContent: {
                what: [
                    require('@/assets/img/showcase-category-overview/what/7-STAUFFER_FAMILIENFILM_Buesi_ohne-Rand.gif'),
                    require('@/assets/img/showcase-category-overview/what/7-STAUFFER_FAMILIENFILM_Federn.gif'),
                    require('@/assets/img/showcase-category-overview/what/7-STAUFFER_FAMILIENFILM_Melone.gif'),
                    require('@/assets/img/showcase-category-overview/what/7-STAUFFER_FAMILIENFILM_Spiegel.gif'),
                    require('@/assets/img/showcase-category-overview/what/7-STAUFFER_FAMILIENFILM_Velogeist.gif'),
                    require('@/assets/img/showcase-category-overview/what/CH-FF1971-A-B-27-01_141_2.gif'),
                    require('@/assets/img/showcase-category-overview/what/CH-FF1971-A-B-27-01_141.gif'),
                    require('@/assets/img/showcase-category-overview/what/CH-FF1971-A-B-27-03_141_15fps.gif'),
                    require('@/assets/img/showcase-category-overview/what/CH-FF1971-A-B-27-08-1_141.gif'),
                    require('@/assets/img/showcase-category-overview/what/CH-FF1971-A-B-27-10_141_beide.gif'),
                    require('@/assets/img/showcase-category-overview/what/GS-STAUFFER-A-05-d-04_F+F.gif'),
                    require('@/assets/img/showcase-category-overview/what/GS-STAUFFER-A-05-d-04.gif'),
                    require('@/assets/img/showcase-category-overview/what/SC_Genres_4_Toeffli.gif'),
                ],
                when: [
                    require('@/assets/img/showcase-category-overview/when/2011_F+F_Logo_Elektrosmog.svg'),
                    require('@/assets/img/showcase-category-overview/when/SC_Uebersicht_WANN_1970_3_NEU.svg'),
                    require('@/assets/img/showcase-category-overview/when/SC_Uebersicht_WANN_1971_2.svg'),
                    require('@/assets/img/showcase-category-overview/when/SC_Uebersicht_WANN_1971_3_NEU.svg'),
                    require('@/assets/img/showcase-category-overview/when/SC_Uebersicht_WANN_1971_4_NEU.svg'),
                    require('@/assets/img/showcase-category-overview/when/SC_Uebersicht_WANN_1971.svg'),
                    require('@/assets/img/showcase-category-overview/when/SC_Uebersicht_WANN_1976.svg'),
                    require('@/assets/img/showcase-category-overview/when/SC_Uebersicht_WANN_1977.svg'),
                    require('@/assets/img/showcase-category-overview/when/SC_Uebersicht_WANN_1990.svg'),
                    require('@/assets/img/showcase-category-overview/when/SC_Uebersicht_WANN_2003.svg'),
                    require('@/assets/img/showcase-category-overview/when/SC_Uebersicht_WANN_aktuell.svg'),
                    require('@/assets/img/showcase-category-overview/when/SC_Uebersicht_WANN_XX.svg'),
                ],
                how: [
                    {de: 'Basisdemokratie', en: 'Grassroots democracy'},
                    {de: 'Computermalerei', en: 'Computer Painting'},
                    {de: 'Experimentalfilm', en: 'Experimental Film'},
                    {de: 'Frauenwerkstatt', en: 'Women’s workshop'},
                    {de: 'Genderfragen', en: 'Gender issues'},
                    {de: 'Hexenkurs', en: 'Witches course'},
                    {de: 'Kunst als Forschung', en: 'Art as Reseach'},
                    {de: 'Kunstphilosophie', en: 'Art Philosophy'},
                    {de: 'Männerkurs', en: 'Men’s course'},
                    {de: 'Teamwork', en: 'Teamwork'},
                ],
                who: [
                    require('@/assets/img/showcase-category-overview/who/_G0K9052_1_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/_G0K9062_1_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/052_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/056_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/090_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/92_S1_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/098_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/CH-FF1971-A-A-06-38_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/CH-FF1971-A-A-08-70_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/CH-FF1971-A-A-12-23_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/CH-FF1971-A-A-12-56_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/CH-FF1971-A-A-25-102_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/CH-FF1971-A-A-25-125_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/CH-FF1971-A-A-25-154_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/CH-FF1971-A-B-09-57_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/CH-FF1971-D-B-05-141_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/SC_Personen_1_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/SK_52_K8_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/SK_79_OB4-Kopie_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/SK_88_B2-Kopie_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/SK_88_B3-Kopie_KORR.jpg'),
                    require('@/assets/img/showcase-category-overview/who/SK_136_F4-Kopie_KORR.jpg'),
                ]
            },
            reloadPreviewItemsOnUpdate: false,
        }
    },
    beforeMount() {
        this.setPreviewItems();
    },
    mounted() {
        this.$refs.viewContent.style.minHeight = this.getViewContainerHeight();
        window.addEventListener('resize', this.onWindowResize);
        this.fetchActivitySplashButtonEntry();
        // set header data
        this.$globalData.setHeaderData({
            title: 'F+F 1971 - Showcase'
        });
    },
    beforeUpdate() {
        if (this.reloadPreviewItemsOnUpdate) {
            this.setPreviewItems();
        } else {
            this.reloadPreviewItemsOnUpdate = true;
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        setPreviewItems() {
            const bgColorsInUse = [];
            for (var i_color = 0; i_color < 4; i_color++) {
                const availableBgColors = this.bgColors.filter(color => !bgColorsInUse.includes(color));
                bgColorsInUse[i_color] = availableBgColors[Math.floor(Math.random() * availableBgColors.length)];
            }
            for (var i_content = 0; i_content < this.previewItems.length; i_content++) {
                const availableContent = this.availablePreviewContent[this.previewItems[i_content].id];
                this.previewItems[i_content].content = availableContent[Math.floor(Math.random() * availableContent.length)];
                this.previewItems[i_content].bgColor = bgColorsInUse[i_content];
            }
        },
        getRandomPreviewContent(availableContent) {
            return availableContent[Math.floor(Math.random() * availableContent.length)];
        },
        getViewContainerHeight() {
            const sectionHeaderHeight = document.getElementsByClassName("section-header")[0].clientHeight;
            const headersTotalSpace = 3 * sectionHeaderHeight;
            return window.innerHeight - headersTotalSpace +'px';
        },
        onWindowResize() {
            if (this.resizeT){
                clearTimeout(this.resizeT);
            }
            const that = this;
            this.resizeT = setTimeout(function() {
                that.$refs.viewContent.style.minHeight = that.getViewContainerHeight();
            }, 200);
        },
        async fetchActivitySplashButtonEntry() {
            const { data } = await fetchActivitySplashButton();
            this.activitySplashButtonEntry = data[0];
        },
    },
}
</script>
