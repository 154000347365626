import { createI18n } from 'vue-i18n'

import en from './en.json'
import de from './de.json'

export const defaultLocale = 'de'

export const languages = {
    en: en,
    de: de,
}

export const messages = Object.assign(languages)

export const i18n = createI18n({
    locale: defaultLocale, // set locale
    localeRouteParam: '',
    fallbackLocale: defaultLocale, // set fallback locale
    defaultLocale: defaultLocale, //CUstom
    messages
})
