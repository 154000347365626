<template>
    <div class="c-archive-active-filters" :class="{'show-all-filter-btns': showAllFilterButtons}">
        <template v-for="(activeFilterGroup, groupKey) in activeFilters" :key="'active-filter-group'+groupKey">
            <template v-for="activeFilterTerm in activeFilterGroup" :key="'active-filter-'+activeFilterTerm">
                <button :data-filter-term="activeFilterTerm" class="active-filter-btn btn-round btn-dark" v-if="isDisplayedAsActiveFilterBtn(groupKey, activeFilterTerm)" @click="$emit('activeFilterRemoved', groupKey, activeFilterTerm)">

                    <span class="filter-label">{{ getFilterButtonLabel(groupKey, activeFilterTerm) }}&nbsp;</span>

                    <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><g stroke="#FFFFFF" fill="none" fill-rule="evenodd"><path d="M.354.357l10.927 10.926M11.281.354L.355 11.281"/></g></svg>
                </button>

                <button v-if="toggleMoreFiltersAfter && toggleMoreFiltersAfter == activeFilterTerm" class="toggle-active-filter-btns show-active-filter-btns btn-round btn-dark" type="button" name="button" @click="showAllFilterButtons = !showAllFilterButtons"><svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg"><path fill="#FFF" d="M6.003 0v6.003H0v.995h6.003V13h.994V6.998H13v-.995H6.997V0z" fill-rule="evenodd"/></svg></button>
            </template>
        </template>

        <template v-for="(preselectedFilterGroup, groupKey) in preselectedFilters" :key="'preselected-filter-group'+groupKey">
            <template v-for="preselectedFilterTerm in preselectedFilterGroup" :key="'preselected-filter-'+preselectedFilterTerm">
                <button :data-filter-term="preselectedFilterTerm" class="active-filter-btn btn-round preselected-filter-btn" :class="{'btn-dark': isActiveFilter(groupKey, preselectedFilterTerm)}" @click="$emit('preselectedFilterToggled', groupKey, preselectedFilterTerm)">
                    <span class="filter-label">{{ getFilterButtonLabel(groupKey, preselectedFilterTerm) }}</span>
                </button>

                <button v-if="toggleMoreFiltersAfter && toggleMoreFiltersAfter == preselectedFilterTerm" class="toggle-active-filter-btns show-active-filter-btns btn-round" type="button" name="button" @click="showAllFilterButtons = !showAllFilterButtons"><svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg"><path fill="#000" d="M6.003 0v6.003H0v.995h6.003V13h.994V6.998H13v-.995H6.997V0z" fill-rule="evenodd"/></svg></button>
            </template>
        </template>
        <button v-if="toggleMoreFiltersAfter" class="toggle-active-filter-btns btn-round" :class="{'btn-dark': !Object.keys(preselectedFilters).length}" type="button" name="button" @click="showAllFilterButtons = !showAllFilterButtons"><svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg"><path d="M0 6.003v.995h13v-.995z" :fill="Object.keys(preselectedFilters).length ? '#000' : '#FFF'" fill-rule="evenodd"/></svg></button>
    </div>
</template>

<script>
export default {
    name: 'ArchiveActiveFilters',
    props: {
        activeFilters: Object,
        preselectedFilters: {
            type:Object,
            default: function() {
                return {}
            }
        },
        toggleMoreFilters: {
            type:Boolean,
            default: false
        }
    },
    emits: [
        'activeFilterRemoved',
        'preselectedFilterToggled'
    ],
    data() {
        return {
            toggleMoreFiltersAfter: false,
            activeFiltersCount: 0,
            showAllFilterButtons: false,
            resizeT: false,
        }
    },
    mounted() {
        this.setToggleMoreFiltersAfter();
        window.addEventListener('resize', this.onWindowResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    watch: {
        activeFilters(newFilters, prevFilters) {
            this.$nextTick(function () {
                this.setToggleMoreFiltersAfter();
            });
        },
    },
    methods: {
        filterExistsInGroup(groupKey, filterTerm) {
            return typeof this.$globalData.archiveCategories[this.$i18n.locale][groupKey] !== 'undefined' && this.$globalData.archiveCategories[this.$i18n.locale][groupKey].filter(function (filterObj) {
                return filterTerm == filterObj.id || filterTerm == filterObj.name;
            }).length;
        },
        isActiveFilter(groupKey, filterId) {
            return (typeof this.activeFilters[groupKey] !== 'undefined' && this.activeFilters[groupKey].includes(filterId))
        },
        isPreselectedFilter(groupKey, filterId) {
            const preselectedFilters = this.preselectedFilters;
            return (typeof preselectedFilters[groupKey] !== 'undefined' && preselectedFilters[groupKey].includes(filterId))
        },
        isDisplayedAsActiveFilterBtn(groupKey, filterTerm) {
            return groupKey == 'search' || (this.filterExistsInGroup(groupKey, filterTerm) && !this.isPreselectedFilter(groupKey, filterTerm))
        },
        getFilterButtonLabel(groupKey, filterTerm) {
            if (groupKey == 'search') {
                return decodeURIComponent(filterTerm);
            } else {
                if (typeof this.$globalData.archiveCategories[this.$i18n.locale][groupKey] !== 'undefined') {
                    const filterItems = this.$globalData.archiveCategories[this.$i18n.locale][groupKey].filter(function (filterObj) {
                        return filterTerm == filterObj.id || filterTerm == filterObj.name;
                    });
                    return filterItems.length ? filterItems[0].name : groupKey+' '+filterTerm;
                } else {
                    return groupKey+' '+filterTerm;
                }
            }
        },
        setToggleMoreFiltersAfter() {
            if (this.toggleMoreFilters) {
                this.toggleMoreFiltersAfter = false;
                this.activeFiltersCount = 0;
                this.$nextTick(function () {
                    const componentHeight = this.$el.clientHeight;
                    const activeFilterButtons = this.$el.querySelectorAll('.active-filter-btn');
                    for (var i = 1; i < activeFilterButtons.length; i++) {
                        if (activeFilterButtons[i].offsetTop > componentHeight) {
                            let toggleMoreFiltersAfterIndex = i-1;
                            if (toggleMoreFiltersAfterIndex >= 0 && activeFilterButtons[toggleMoreFiltersAfterIndex].offsetLeft + activeFilterButtons[toggleMoreFiltersAfterIndex].clientWidth > this.$el.clientWidth - 45) {
                                toggleMoreFiltersAfterIndex = i-2;
                            }
                            this.toggleMoreFiltersAfter = activeFilterButtons[toggleMoreFiltersAfterIndex].dataset.filterTerm;
                            break;
                        }
                    }
                })
            }
        },
        onWindowResize() {
            if (this.resizeT){
                clearTimeout(this.resizeT);
            }
            const that = this;
            this.resizeT = setTimeout(function() {
                that.setToggleMoreFiltersAfter();
            }, 200);
        },
    }
}
</script>
