<template>
    <div class="c-archive-filters container-fluid d-flex flex-column">
        <div ref="archiveFilterHeader" class="archive-filter-header">
            <h2 class="section-title">Archiv</h2>
            <button @click="$emit('closeFilters')" class="close-btn btn-icon">
                <svg class="d-none d-md-block" width="35" height="35" xmlns="http://www.w3.org/2000/svg"><g stroke="#000" stroke-width="1.357" fill="none" fill-rule="evenodd"><path d="M.914.48L34.52 34.087M34.52.48L.915 34.087"/></g></svg>
                <svg class="d-block d-md-none" width="29" height="29" xmlns="http://www.w3.org/2000/svg"><g stroke="#000" fill="none" fill-rule="evenodd"><path d="M1.306 1.305l26.568 26.566M27.871 1.306L1.305 27.874"/></g></svg>
            </button>

            <div class="entries-count-row d-none d-md-block">
                <span>{{$tc('archive.index.resultsWithCount', currentItemsTotal)}}</span>
            </div>
            <div class="active-filters-row">
                <archive-active-filters :active-filters="currentActiveFilters" @activeFilterRemoved="removeActiveFilterByTerm" key="archive-filters-active-filters" />
            </div>
        </div>
        <div class="row archive-filter-content">
            <div ref="filterNavColumn" class="col-md-6 d-flex flex-column filter-nav-column">
                <ul class="filter-nav">
                    <li class="filter-nav-item" v-for="(filterGroup, groupKey, index) in this.$globalData.archiveCategories[$i18n.locale]" :key="'link-group-'+groupKey+'-'+$i18n.locale" :class="{'active-nav-item': displayedFilterGroup == groupKey}">
                        <a @click="showFilterGroup(groupKey)" v-html="$t('archive.index.filterGroups.'+groupKey)"></a>
                        <span v-if="getGroupsActiveFiltersTotal(groupKey)" class="active-filter-count">({{getGroupsActiveFiltersTotal(groupKey)}})</span>

                        <div v-if="isMobile" class="mobile-filter-group" :class="{'mobile-filter-group-active' : displayedFilterGroup == groupKey }" :style="{ 'height' : (displayedFilterGroup == groupKey ? getMobileFilterListHeight(index) : 0) }">
                            <ul class="mobile-filter-list filter-list">
                                <template v-for="(filter, filterIndex) in indexedFilters[groupKey]" :key="'filter-'+filterIndex">
                                    <li v-if="filter.type && filter.type == 'divider'" class="filter-list-divider">
                                        {{ filter.name }}
                                    </li>
                                    <li v-else class="filter-list-item" @click="toggleActiveFilter(groupKey, filter)" :class="{'active': isActiveFilterItem(groupKey, filter), 'disabled': !isAvailableFilterItem(groupKey, filter)}">
                                        <span>{{ filter.name }}</span>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </li>
                </ul>
                <form class="search-form" @submit.prevent="onSearchFormSubmit" :class="{'has-text': searchFormValue != ''}">
                    <input ref="searchField" type="text" name="" v-model="searchFormValue">
                    <button class="search-submit-btn btn">
                        {{$t('archive.index.searchSubmitLabel')}}
                    </button>
                    <div v-show="searchFormValue == ''" class="search-placeholder">
                        {{$t('archive.index.searchPlaceholder')}}
                    </div>
                </form>

                <div class="filter-btns-row mt-auto">
                    <button class="archive-apply-filters-btn btn" @click="resetFilters">{{$t('archive.index.resetFiltersButtonLabel')}}</button>

                    <button class="archive-apply-filters-btn btn" @click="applyFilters">
                        {{$t('archive.index.applyFiltersButtonLabel')}}
                    </button>
                </div>

            </div>
            <div v-if="!isMobile" class="col-sm-6 pl-md-0">
                <div class="filter-group" v-for="(filterGroup, groupKey) in indexedFilters" :key="'group-'+groupKey" v-show="displayedFilterGroup == groupKey">
                    <ul class="filter-list">
                        <template v-for="(filter, filterIndex) in filterGroup" :key="'filter-'+filterIndex">
                            <li v-if="filter.type && filter.type == 'divider'" class="filter-list-divider">
                                {{ filter.name }}
                            </li>
                            <li v-else class="filter-list-item" @click="toggleActiveFilter(groupKey, filter)" :class="{'active': isActiveFilterItem(groupKey, filter), 'disabled': !isAvailableFilterItem(groupKey, filter)}">
                                <span>{{ filter.name }}</span>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <p v-if="isReloadingFilters" class="loader-spinner"><span>Loading...</span></p>
    </div>
</template>

<script>
import ArchiveActiveFilters from '@/components/ArchiveActiveFilters.vue';

import { fetchArchiveEntries } from '../http/fetchApi';

export default {
    name: 'ArchiveFilters',
    components: {
        ArchiveActiveFilters,
    },
    props: {
        itemsTotal: Number,
        activeFilters: {
            type: Object,
            default: function() {
                return {}
            }
        },
        availableFilters: {
            type: Object,
            default: function() {
                return {}
            }
        }
    },
    data() {
        return {
            currentItemsTotal:this.itemsTotal,
            filterGroups: {},
            displayedFilterGroup: null,
            currentAvailableFilters: {},
            currentActiveFilters: {},
            searchFormValue:'',
            isMobile: false,
            resizeT: false,
            dividerItems: null,
            indexedFilters:{},
            isReloadingFilters: false,
        }
    },
    emits: [
        'closeFilters',
        'filterChanged'
    ],
    mounted() {
        this.currentActiveFilters = JSON.parse(JSON.stringify(this.activeFilters)); // deep Copy active filters
        this.setAvailableFilters(this.availableFilters);
        if (window.innerWidth < this.$globalData.mobileBreakpoint) {
            this.isMobile = true
        } else {
            this.displayedFilterGroup = 'name';
        }
        this.createDividerItems();
        this.createIndexedFilters();
        window.addEventListener('resize', this.onWindowResize);
    },
    updated() {
        this.setDesktopFilterNavColumnHeight();
    },
    unmounted() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        applyFilters() {
            if (JSON.stringify(this.currentActiveFilters) == JSON.stringify(this.activeFilters)) {
                this.$emit('closeFilters')
            } else {
                if (this.$route.name == 'ShowcaseEntry') {
                    this.$route.meta.activeView = 'archive'
                }
                this.$router.push({ name: 'ArchiveIndex', query: this.currentActiveFilters, params: { locale: this.$route.params.locale} });
            }
        },
        resetFilters() {
            this.currentActiveFilters = {};
            this.applyFilters();
        },
        showFilterGroup(name) {
            this.displayedFilterGroup = (this.isMobile && this.displayedFilterGroup == name ? null : name);
        },
        removeActiveFilterByTerm(groupKey, filterTerm) {
            this.currentActiveFilters[groupKey] = this.currentActiveFilters[groupKey].filter(filter => filter !== filterTerm );
            if (!this.currentActiveFilters[groupKey].length) {
                delete this.currentActiveFilters[groupKey]
            }
            this.fetchEntries(this.currentActiveFilters);
        },
        toggleActiveFilter(groupKey, filter) {
            this.currentActiveFilters[groupKey] = this.currentActiveFilters[groupKey] || [];
            if (this.currentActiveFilters[groupKey].includes(filter.id.toString()) || this.currentActiveFilters[groupKey].includes(filter.name.toString())) {
                this.currentActiveFilters[groupKey] = this.currentActiveFilters[groupKey].filter(filterTerm => (filterTerm !== filter.id.toString() && filterTerm !== filter.name.toString()) );
                if (!this.currentActiveFilters[groupKey].length) {
                    delete this.currentActiveFilters[groupKey]
                }
            } else {
                if (groupKey == 'name' || groupKey == 'genre' || groupKey == 'subject') {
                    this.currentActiveFilters[groupKey].push(filter.name.toString());
                } else {
                    this.currentActiveFilters[groupKey].push(filter.id.toString());
                }
            }
            this.fetchEntries(this.currentActiveFilters);
        },
        setAvailableFilters(filters) {
            this.currentAvailableFilters = JSON.parse(JSON.stringify(filters));
        },
        isActiveFilterItem(groupKey, filter) {
            return typeof this.currentActiveFilters[groupKey] !== 'undefined' && ( this.currentActiveFilters[groupKey].includes(filter.id.toString()) || this.currentActiveFilters[groupKey].includes(filter.name.toString()));
        },
        isAvailableFilterItem(groupKey, filter) {
            let hasOtherUniqueFilter = (groupKey == 'year' && this.getGroupsActiveFiltersTotal(groupKey) && !this.isActiveFilterItem(groupKey, filter)) || (groupKey == 'mediatypes' && this.getGroupsActiveFiltersTotal(groupKey) && !this.isActiveFilterItem(groupKey, filter));

            return !hasOtherUniqueFilter && typeof this.currentAvailableFilters[groupKey] !== 'undefined' && typeof  this.currentAvailableFilters[groupKey][filter.id.toString()] !== 'undefined';

        },
        getGroupsActiveFiltersTotal(groupKey) {
            return typeof this.currentActiveFilters[groupKey] !== 'undefined' ? this.currentActiveFilters[groupKey].length : 0;
        },
        async fetchEntries(params) {
            this.isReloadingFilters = true;
            const { data } = await fetchArchiveEntries(params);
            this.currentItemsTotal = data.total;
            this.setAvailableFilters(data.subfilters);
            this.isReloadingFilters = false;
        },
        onSearchFormSubmit() {
            this.currentActiveFilters['search'] = this.currentActiveFilters['search'] || [];
            this.currentActiveFilters['search'].push(this.searchFormValue);
            this.fetchEntries(this.currentActiveFilters);
            this.$refs.searchField.blur();
            this.searchFormValue = '';
        },
        getMobileFilterListHeight(index) {
            const filterListElements = document.querySelectorAll('.mobile-filter-list');
            if (filterListElements[index] || false) {
                return filterListElements[index].clientHeight + 'px';
            } else {
                return null;
            }
        },
        createDividerItems() {
            let i = 97;
            this.dividerItems = [...Array(26)].map(() => {
                return {
                    name: String.fromCharCode(i++).toUpperCase(),
                    type: 'divider'
                }
            });
        },
        createIndexedFilters() {
            for (var filterGroupKey in this.$globalData.archiveCategories[this.$i18n.locale]) {
                let indexedFilterGroup = [...this.$globalData.archiveCategories[this.$i18n.locale][filterGroupKey]];
                if (indexedFilterGroup.length && (filterGroupKey == 'name' || filterGroupKey == 'genre' || filterGroupKey == 'subject' )) {
                    indexedFilterGroup = [...this.dividerItems, ...indexedFilterGroup];
                }
                this.indexedFilters[filterGroupKey] = indexedFilterGroup.sort((a, b) => {
                    const compareValueA = (a.indexName ?? a.name).toString().toUpperCase();
                    const compareValueB = (b.indexName ?? b.name).toString().toUpperCase();
                    return compareValueA > compareValueB ? 1 : -1;
                });
            }
        },
        setDesktopFilterNavColumnHeight() {
            const filterNavColumn = this.$refs.filterNavColumn;
            if (this.isMobile) {
                filterNavColumn.style.minHeight = 0;
            } else {
                const filterNavColumn = this.$refs.filterNavColumn;
                const archiveFilterHeaderRect = this.$refs.archiveFilterHeader.getBoundingClientRect();
                const filterMinHeight = window.innerHeight - archiveFilterHeaderRect.top - archiveFilterHeaderRect.height;
                filterNavColumn.style.minHeight = filterMinHeight + 'px';
                if (filterNavColumn.clientHeight <= filterMinHeight) { // workaround safari which ignores sticky here
                    filterNavColumn.classList.add('fixed');
                    filterNavColumn.style.top = (archiveFilterHeaderRect.height+archiveFilterHeaderRect.top) + 'px';
                    if (filterNavColumn.nextSibling && typeof filterNavColumn.nextSibling.classList !== 'undefined') {
                        filterNavColumn.nextSibling.classList.add('offset-sm-6');
                    }
                } else {
                    filterNavColumn.classList.remove('fixed');
                    filterNavColumn.style.top = archiveFilterHeaderRect.height + 'px';
                    if (filterNavColumn.nextSibling && typeof filterNavColumn.nextSibling.classList !== 'undefined') {
                        filterNavColumn.nextSibling.classList.remove('offset-sm-6');
                    }
                }
            }
        },
        onWindowResize() {
            if (this.resizeT){
                clearTimeout(this.resizeT);
            }
            const that = this;
            this.resizeT = setTimeout(function() {
                if (window.innerWidth < that.$globalData.mobileBreakpoint) {
                    that.isMobile = true;
                    that.$nextTick(function () {
                        const filterGroupElement = document.querySelector('.mobile-filter-group-active');
                        if (filterGroupElement || false) {
                            return filterGroupElement.style.height =  filterGroupElement.querySelector('.mobile-filter-list').clientHeight + 'px';
                        }
                    })
                } else {
                    that.isMobile = false;
                }
                that.setDesktopFilterNavColumnHeight();
            }, 200);
        },
    }
}
</script>
