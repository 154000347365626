<template>
    <div class="v-home">
        <div class="view-content">
            <div class="intro-text container-fluid">
                <h2 class="intro-text-heading" v-html="$t('appDescription')"></h2>
            </div>

            <main-navigation />
        </div>
    </div>
</template>

<script>
import MainNavigation from '@/components/MainNavigation.vue';

export default {
    name: 'Home',
    components: {
        MainNavigation
    },
    props: {
        slug: String,
    },
    data() {
        return {
            redirectT: false
        }
    },
    mounted() {
        // set header data
        this.$globalData.setHeaderData({
            title: 'F+F 1971',
            description: this.$t('appMetaDescription'),
        });
    },
    activated() {
        const that = this;
        this.redirectT = setTimeout(function() {
            that.$router.push({ name: 'ShowcaseCategoryIndex', params: { locale: that.$route.params.locale} });
        }, 2000);
    },
    deactivated() {
        if (this.redirectT){
            clearTimeout(this.redirectT);
        }
    },
}
</script>
