import { createApp } from 'vue'
import App from './App.vue'

import router from './router'

import { i18n } from './i18n'

const app = createApp(App);

app.config.globalProperties.$globalData = {
    archiveCategories: {
        'de': [],
        'en': []
    },
    availableCategoryFilters: [],
    storedArchiveEntries: [],
    mobileBreakpoint: 768,
    isTouchDevice: false,
    createMetaTag(attributes, type) {
        const elementType = type || 'meta'
        let meta = document.createElement(elementType);
        for (var key in attributes) {
            meta.setAttribute(key, attributes[key]);
        }
        document.getElementsByTagName('head')[0].appendChild(meta);
        return meta;
    },
    setHeaderData(data) {
        if (data.title) {
            const cleanTitle = data.title.replace('&shy;', '');
            document.title = cleanTitle;
            const ogTitle = document.querySelector('meta[property="og:title"]');
            if (ogTitle !== null) {
                ogTitle.content = cleanTitle;
            }
            const twitterTitle = document.querySelector('meta[name="twitter:title"]');
            if (twitterTitle !== null) {
                twitterTitle.content = cleanTitle;
            }
        }

        const url = window.location.href;
        const canonical = document.querySelector('link[rel="canonical"]') ||  app.config.globalProperties.$globalData.createMetaTag({ rel: 'canonical' }, 'link');
        canonical.href = url;

        const ogUrl = document.querySelector('meta[property="og:url"]') || app.config.globalProperties.$globalData.createMetaTag({ property: 'og:url'});
        ogUrl.content = url;

        /*if (data.url) {
            const canonical = document.querySelector('link[rel="canonical"]');
            if (canonical !== null) {
                canonical.href = data.url;
            }
            const ogUrl = document.querySelector('meta[property="og:url"]');
            if (ogUrl !== null) {
                ogUrl.content = data.url;
            }
        }*/
        if (data.description) {
            const description = (data.description.length > 200 ? data.description.substr(0, 200)+'...' : data.description).replace( /(<([^>]+)>)/ig, ' ');

            const metaDescription = document.querySelector('meta[name="description"]') || app.config.globalProperties.$globalData.createMetaTag({ name: 'description'});
            metaDescription.content = description;

            const ogDescription = document.querySelector('meta[property="og:description"]') || app.config.globalProperties.$globalData.createMetaTag({ property: 'og:description'});
            ogDescription.content = description;

            const twitterDescription = document.querySelector('meta[name="twitter:description"]') || app.config.globalProperties.$globalData.createMetaTag({ name: 'twitter:description'});
            twitterDescription.content = description;
        }
        if (data.image) {
            const ogImage = document.querySelector('meta[property="og:image"]') || app.config.globalProperties.$globalData.createMetaTag({ property: 'og:image'});
            ogImage.content = data.image;

            const twitterImage = document.querySelector('meta[name="twitter:image"]') || app.config.globalProperties.$globalData.createMetaTag({ name: 'twitter:image'});
            twitterImage.content = data.image;
        }
    }

};

//Smooth Scroll behavior polyfill for Safari
import smoothscroll from 'smoothscroll-polyfill';
// kick off the polyfill!
smoothscroll.polyfill();

//Google Analytics
import VueGtag from "vue-gtag";

app
.use(i18n)
.use(router)
.use(VueGtag, {
  config: { id: 'G-1QPNZ1CJ35', router }
})
.mount('#app')
