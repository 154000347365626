<template>
    <div id="c-showcase-category-overview" class="c-showcase-category-overview container-fluid px-0">
        <div class="row small-gutters">
            <div v-for="(previewItem, itemIndex) in previewItems" :key="'previewItem'+itemIndex" class="col-md-6 category-index-col">
                <div class="category-preview-item" :style="{ backgroundColor: previewItem.contentType == 'term' ? '#FFF' : previewItem.bgColor }">
                    <div class="preview-content" :class="previewItem.contentType+'-content'">

                        <span v-if="previewItem.contentType == 'term'" class="btn" :style="{ backgroundColor: previewItem.bgColor, borderColor: previewItem.bgColor }"><span>{{ previewItem.content[this.$i18n.locale] }}</span></span>

                        <img v-else :src="previewItem.content" alt="previewItem.title">

                        <router-link :to="{ name: 'ShowcaseIndex', params: { locale: $route.params.locale, slug: $t('showcase.categories.'+previewItem.id+'.slug')} }" class="category-preview-link">
                            <span :ref="'mouseoverTitle'+previewItem.id" class="mouseover-title" :class="previewItem.contentType == 'term' ? 'outline-transparent-text' : 'outline-transparent-text-white'">{{$t('showcase.categories.'+previewItem.id+'.name')}}?</span>
                            <span class="mouseoverlay" @mouseenter="setMinMaxMouseoverOffset($event, 'mouseoverTitle'+previewItem.id)" @mousemove="moveMouseoverTitle($event, 'mouseoverTitle'+previewItem.id)"></span>
                        </router-link>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShowcaseCategoryOverview',
    props: {
        previewItems: Array
    },
    data() {
        return {
            mouseoverOffset: {},
            resizeT: false,
            isMobileAndOrTouch: false,
        }
    },
    mounted() {
        this.setIsMobileAndOrTouch();
        window.addEventListener('resize', this.onWindowResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        moveMouseoverTitle(e, ref) {
            if (!this.isMobileAndOrTouch ) {
                this.$refs[ref].style.left = Math.min(Math.max(e.offsetX, this.mouseoverOffset.xMin), this.mouseoverOffset.xMax)+'px';
                this.$refs[ref].style.top = Math.min(Math.max(e.offsetY, this.mouseoverOffset.yMin), this.mouseoverOffset.yMax)+'px';
            }
        },
        setMinMaxMouseoverOffset(e, rel) {
            if (this.$refs[rel] !== null) {
                const titleW = this.$refs[rel].clientWidth;
                const titleH = this.$refs[rel].clientHeight;
                this.mouseoverOffset = {
                    xMin: titleW/2,
                    xMax: e.target.clientWidth-titleW/2,
                    yMin: titleH/2,
                    yMax: e.target.clientHeight-titleH/2,
                };
            }
        },
        setIsMobileAndOrTouch() {
            this.isMobileAndOrTouch = window.innerWidth < this.$globalData.mobileBreakpoint || this.$globalData.isTouchDevice;
        },
        onWindowResize() {
            if (this.resizeT){
                clearTimeout(this.resizeT);
            }
            const that = this;
            this.resizeT = setTimeout(function() {
                that.setIsMobileAndOrTouch();
            }, 200);
        }
    }
}
</script>
